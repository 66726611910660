import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../utils/sanityConfig"

const LegalPage = ({ data }) => {
  const { i18n } = useTranslation("home")
  const language = i18n.language

  return (
    <Layout>
      <Seo title={"Cseriti - Adatkezelési tájékoztató"} lang={language} />
      <Container>
        <Row>
          <Col xs={12} className="py-5 mt-5">
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={data.sanityLegalPage._rawText[language]}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 1000, h: 400, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query LegalPageQuery {
    sanityLegalPage {
      id
      _rawText
    }
  }
`

export default LegalPage
